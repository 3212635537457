@use 'common'

@font-face
	font-family: common.$font-jz
	src: url('/fonts/jakubzich/jakubzichceblack-webfont.woff2') format('woff2'), url('/fonts/jakubzich/jakubzichceblack-webfont.woff') format('woff')
	font-weight: bold
	font-style: normal

@font-face
	font-family: common.$font-jz
	src: url('/fonts/jakubzich/jakubzichce-webfont.woff2') format('woff2'), url('/fonts/jakubzich/jakubzichce-webfont.woff') format('woff')
	font-weight: normal
	font-style: normal
