$siteWidth: 1290
$siteWidthNormal: 975px
$siteWidthWide: 1400px
$siteWidthLarge: 2048px
$gratio: 1.618
$pi: 3.14159
$siteContentPaddingBottom: 50px

$base-font-size: 14px
$font-family-base: 'Source Sans Pro', sans-serif
$font-family-head: $font-family-base
$font-jz: 'JZ'

$radius: .25em
$spacing: 25px
$padding: 15px

$c-main: #e43422
$c-bg: #e2e2e2
$c-comp: #666
$c-blue: #59abe3
$c-white: #ffffff
$c-black: #000000
$c-red: #e74c3c
$c-grey: #7e7e7e
$c-grey-semidark: #4a4a4a
$c-grey-dark: #1d1d1d
$c-grey-dark2: #555555
$c-grey-dark3: #333333
$c-grey-light: #d8d8d8
$c-grey-light2: #cccccc
$c-grey-light3: #f1f1f1
$c-grey-light3: #dddddd
$c-grey-light4: #fafafa
$c-grey-light5: #929292
$c-grey-light6: #d7d7d7
$c-grey-light7: #aeaeae
$c-grey-light8: #fbfbfb
$c-grey-light9: #f8f8f8
$c-grey-superlight: #f5f5f5
$c-border: #181818
$c-border2: #aaaaaa
$c-menu-button-hover: #353535

$text-color: #1e1e1e
$text-color2: #676767
$text-color3: #eeeeee
$text-back: #f0f0f0

$break320: 320px
$break380: 380px
$break480: 480px
$break560: 560px
$break768: 768px
$break992: 992px
$break1200: 1200px
$break1600: 1600px

// Animations

@keyframes arrowDownBounce
	0%
		transform: translateY(0px) rotate(90deg)
	50%
		transform: translateY(10px) rotate(90deg)
	100%
		transform: translateY(0px) rotate(90deg)

@keyframes zoomControlsIn
	0%
		background-size: 100%
		opacity: 1
	50%
		opacity: 1
	100%
		background-size: 14px
		opacity: 0.33

@keyframes fadeInFromNone
	0%
		display: none
		opacity: 0
	1%
		display: block
		opacity: 0
	100%
		display: block
		opacity: 1

$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1)
