@use 'common'
@use 'fonts-JZ'

*, *:before, *:after
	box-sizing: border-box

body
	font-family: common.$font-family-base
	font-size: common.$base-font-size
	line-height: common.$gratio
	color: common.$text-color
	overflow-y: scroll
	margin: 0

	@media (min-width: common.$break992)
		transition: transform .2s
		transform: translateX(calc(-1 * var(--page-desktop-menu-sidebar-width, 0px)))

a
	color: common.$c-main

	img
		border: none

h1, h2, h3, h4, h5, h6
	font-family: common.$font-family-head
	font-weight: bold
	line-height: 1
	margin: 0 0 common.$spacing 0
	text-rendering: optimizeLegibility

input[type=submit]
	-webkit-appearance: none

p
	margin: 0

blockquote
	font-style: italic

ul, li
	margin: 0
	padding: 0

.grecaptcha-badge
	z-index: 1

:root
	scroll-behavior: smooth

	--page-header-height: 95px
	--page-desktop-menu-sidebar-width: 0
	--page-horizontal-spacing: 15px
	--page-vertical-spacing: var(--page-horizontal-spacing)

	--content-width-normal: #{common.$siteWidthNormal}
	--content-width-wide: #{common.$siteWidthWide}
	--content-width-large: #{common.$siteWidthLarge}

	--site-width: calc(#{common.$siteWidth} * 1px)
	--ratio: (920 / #{common.$siteWidth})
	--site-width-ratio: calc(var(--ratio) * 100%)
	--site-col-width: calc(var(--ratio) * 1290px)

	--border-radius: #{common.$radius}

	// plyr
	--plyr-color-main: #{common.$c-main}
